<template>
  <div>
    <div class="home" v-if="$route.meta.show">
      <tk-header></tk-header>
      <router-view></router-view>
      <tk-footer></tk-footer>
    </div>
    <keep-alive><router-view v-if="!$route.meta.show"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import header from "@/components/header";
import footer from "@/components/footer";
export default {
  components: {
    "tk-header": header,
    "tk-footer": footer,
  },
  data() {
    return {

    }
  }
}
</script>
<style>
</style>
