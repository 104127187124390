<template>
  <div>
      <div :style="header">
        <div class="con">
          <span @click="home"><img src="../assets/logo.png" class="logo" /></span>
          <div class="nav">
            <span @click="home" class="title-size" title="首页"><a>首页</a></span>
            <span @click="companyProfile" class="title-size" title="公司简介"
            ><a>公司简介</a></span
            >
            <span @click="pressCenter" class="title-size" title="新闻中心"
            ><a>新闻中心</a></span
            >
            <span @click="solution" class="title-size" title="解决方案"
            ><a>解决方案</a></span
            >
            <span @click="projectCase" class="title-size" title="项目案例"
            ><a>技术应用</a></span
            >
            <span @click="partner" class="title-size" title="合作伙伴"
            ><a>企业风采</a></span
            >
            <span @click="contactus" class="title-size" title="联系我们"
            ><a>联系我们</a></span
            >
            <span @click="staffreCruitment" class="title-size" title="员工招聘"
            ><a>员工招聘</a></span
            >
            <span
              @click="subsidiaryCorporation"
              class="title-size"
              title="子公司"
            ><a>子公司</a></span
            >
          
          </div>
        </div>
      </div>




  </div>
</template>

<script>
export default {
  data() {
    return {
      header: {
        position:'fixed',
        zIndex:'99',
        display: 'flex',
        minwidth: '1250px',
        left: '0',
        right:'0',
        backgroundColor: '',



      },
      scrollTop:''
    };
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
     this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(this.scrollTop)
      if (this.scrollTop=='0'){
        this.header.backgroundColor=""
      }else{
        this.header.backgroundColor="#ffffff"
      }

    },
    home() {
      this.$router.push({ path: "/index" });
    },
    companyProfile() {
      this.$router.push({ path: "/companyprofile" });
    },
    pressCenter() {
      this.$router.push({ path: "/presscenter" });
    },
    solution() {
      this.$router.push({ path: "/solution" });
    },
    projectCase() {
      this.$router.push({ path: "/projectcase" });
    },
    partner() {
      this.$router.push({ path: "/partner" });
    },
    contactus() {
      this.$router.push({ path: "/contactus" });
    },
    staffreCruitment() {
      this.$router.push({ path: "/staffrecruitment" });
    },
    subsidiaryCorporation() {
      this.$router.push({ path: "/subsidiarycorporation" });
    },
    panoramaview(){
      this.$router.push({ path: "panoramicview" });
    }
  },
};
</script>
<style scoped>
a {
  color: black;
  height: 33px;
  width: 30px;
  padding: 40px 20px 37.5px 20px;
}
a:hover {
  text-decoration: underline #8cc6ee 3px solid;
  /*border-bottom: 1px solid #8cc6ee;*/
  text-underline-offset: 5px;
  cursor: pointer;
}
.header {
  /*background-color: #ffffff;*/
/*margin-left: calc(20% - 10px);*/
  z-index: 99;
  position: absolute;
  display: flex;
  min-width: 1250px;
  left: 0;
  right:0;
  margin: 0 auto;

  /*top: 120px;*/

}
.con {
/*position: relative;*/
  margin: auto;
}
.logo {
  float: left;
  height: 85px;
  /*margin-left: 100px;*/
  margin-top: -5px;
  margin-bottom: 5px;
  cursor: pointer;

}
.nav {

  min-width: 1250px;
  height: 40px;
  margin-top: 35px;
}
.title-size {
  font-size: 18px;
  color: #ffffff;
}

</style>
