import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {Carousel,Pagination,Button,Divider,CarouselItem} from 'element-ui'
import './css/global.css'
import animated from 'animate.css'

document.addEventListener('contextmenu', function (e) {
  e.preventDefault()
})

Vue.config.devtools = false
Vue.config.productionTip = false
Vue.use(animated)
Vue.use(Carousel)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Divider)
Vue.use(CarouselItem)
new Vue({
  router,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
