<template>
  <div class="foot">
    <div class="section">
      <div class="foot-fl">
        <ul>
          <li @click="companyProfile">
            <a class="btmenu" target="_self">公司简介</a>
          </li>

          <li @click="pressCenter">
            <a class="btmenu" target="_self">新闻中心</a>
          </li>

          <li @click="solution">
            <a class="btmenu" target="_self">解决方案</a>
          </li>

          <li @click="projectCase">
            <a class="btmenu" target="_self">模块设备</a>
          </li>

          <li @click="partner">
            <a class="btmenu" target="_self">项目案例</a>
          </li>

          <li @click="contactus">
            <a class="btmenu" target="_self">联系我们</a>
          </li>
        </ul>
        <div class="unline"></div>
        <div class="foot-contact">
          <h3>联系我们</h3>
          <span>苏州腾康环保科技有限公司</span>
          <p>
            电话：0512-68709231 / 15366204535 公司邮箱：tengkanghb@aliyun.com
          </p>
          <p>
            网址：http://tengkang-cn.com/<br />设备基地:扬州市宝应县小官庄镇工业集中区范石路2号<br />办公地址：吴中区东方大道988号吴中大厦
          </p>
        </div>
        <div class="foot-fr fr">
          <img height="230px" src="../assets/images/home/wx.png" />
        </div>
      </div>
    </div>
    <div class="bottom-size">
      <p class="size-content" >
        版权所有：苏州腾康环保科技有限公司  <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">苏ICP备2022041159号-1</a>
        <img   src="../assets/beian.png"  style="margin: 0px 10px 0px 10px">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050702011179">苏公网安备 32050702011179号</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    companyProfile() {
      this.$router.push({ path: "/companyprofile" });
    },
    pressCenter() {
      this.$router.push({ path: "/presscenter" });

    },
    solution() {
      this.$router.push({ path: "/solution" });
    },
    projectCase() {
      this.$router.push({ path: "/modularEquipment" });
    },
    partner() {
      this.$router.push({ path: "/projectcase" });
    },
    contactus() {
      this.$router.push({ path: "/contactus" });
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-size {
  background: #8cc6ee;
  line-height: 40px;
  margin-top: 63px;
  .size-content {
    width: 1200px;
    height: 40px;
    margin: auto;
  }
}
.unline {
  border-bottom: 1px solid #8cc6ee;
  margin-right: 290px;
}
.foot {
  height: 400px;
  background: #8cc6ee;
  color: #fff;
  padding: 40px 0;
  overflow: hidden;
  min-width: 1250px;

}
.section {
  width: 100%;
}
.foot-fl {
  width: 1200px;
  margin: auto;
}
.foot .foot-fl ul {
  width: 100%;
  overflow: hidden;
  line-height: 50px;
}

.foot .foot-fl ul li a {
  color: #fff;
}
.foot .foot-fl ul li {
  width: 14.25%;
  float: left;
  font-size: 16px;
}

.foot .foot-fl .foot-contact h3 {
  font-size: 25px;
  color: white;
}

.foot .foot-fl .foot-contact {
  width: 100%;
  padding-top: 30px;
  overflow: hidden;
}

.foot .foot-fl .foot-contact span {
  font-size: 17px;
  display: block;
  padding: 10px 0;
}

.foot .foot-fl .foot-contact p {
  font-size: 14px;
  line-height: 28px;
}

.foot .foot-fr {
  width: 28%;
}

.fr {
  float: right;
}

.foot .foot-fr img {
  margin-left: -20px;
  margin-top: -195px;
}
</style>
