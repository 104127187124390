
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () =>
      import("../views/home/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
      keepAlive: true,
    },
  },
  {
    path: "/panoramicview",
    name: "panoramicview",
    component: () =>
    import("../views/panoramicview/fullview.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/companyprofile",
    name: "companyprofile",
    component: () =>
    import("../views/companyprofile/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/presscenter",
    name: "presscenter",
    component: () =>
    import("../views/presscenter/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/solution",
    name: "solution",
    component: () =>
    import("../views/solution/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/projectcase",
    name: "projectcase",
    component: () =>
    import("../views/projectcase/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/projectcaseInformation",
    name: "projectcaseInformation",
    component: () =>
    import("../views/projectcase/projectcaseInformation.vue"
      ),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/partner",
    name: "partner",
    component: () =>
    import("../views/partner/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () =>
    import("../views/contactus/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/staffrecruitment",
    name: "staffrecruitment",
    component: () =>
    import("../views/staffrecruitment/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/subsidiarycorporation",
    name: "subsidiarycorporation",
    component: () =>
    import("../views/subsidiarycorporation/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/modularEquipment",
    name: "modularEquipment",
    component: () =>
    import(
        "../views/module/modularEquipment.vue"
      ),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/partner",
    name: "partner",
    component: () =>
    import("../views/partner/index.vue"),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () =>
    import(
        "../views/presscenter/newsDetail.vue"
      ),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/corporateNews",
    name: "corporateNews",
    component: () =>
    import(
        "../views/newsDetail/corporateNews.vue"
      ),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/industryTrends",
    name: "industryTrends",
    component: () =>
    import(
        "../views/newsDetail/industryTrends.vue"
      ),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/wuxuePlant",
    name: "wuxuePlant",
    component: () =>
    import(
        "../views/panoramicview/demo1.vue"
      ),
    meta: {
      show: false, // 设置导航隐藏显示
    },
  },
  {
    path: "/solutionDetail",
    name: "solutionDetail",
    component: () =>
    import(
        "../views/solution/solutionDetail.vue"
      ),
    meta: {
      show: true, // 设置导航隐藏显示
    },
  },
  {
    path: "/suzhouPlant",
    name: "suzhouPlant",
    component: () =>
    import(
        "../views/panoramicview/demo2.vue"
      ),
    meta: {
      show: false, // 设置导航隐藏显示
    },
  },
  {
    path: "/kunshanPlant",
    name: "kunshanPlant",
    component: () =>
    import(
        "../views/panoramicview/demo3.vue"
      ),
    meta: {
      show: false, // 设置导航隐藏显示
    },
  },

];

// //获取原型对象上的push函数
// const originalPush = VueRouter.prototype.push
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// const router = new VueRouter({
//   mode: 'hash',
//   // base: process.env.BASE_URL,
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition
//     } else {
//       if (from.meta.keepAlive) {
//         from.meta.savedPosition = document.body.scrollTop
//       }
//       return { x: 0, y: to.meta.savedPosition || 0 }
//     }
//   }
// })
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
